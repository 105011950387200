import displayNotification from "./displayNotification";
import confirmBox from "./confirmBox";
//import purgeCacheAndIDB from "./purgeCacheAndIDB";


export default async function paymentsHandler() {
	
	$('#exp').keyup(function(e) {
		if(e.keyCode != 8){
			let exp = $('#exp').val();
			let mask = '';
			
			if(typeof(exp[0]) !== 'undefined'){
				mask += exp[0];
			}
			if(typeof(exp[1]) !== 'undefined'){
				mask += exp[1];
			}
			if(typeof(exp[2]) !== 'undefined' && exp[2] === '/'){
				mask += exp[2];
			}else{
				if(typeof(exp[0]) !== 'undefined' && typeof(exp[1]) !== 'undefined'){
					mask += '/';
				}
			}
			if(typeof(exp[3]) !== 'undefined'){
				mask += exp[3];
			}
			if(typeof(exp[4]) !== 'undefined'){
				mask += exp[4];
			}
			$('#exp').val(mask);
			$('#exp').focus();
		}
	});

    $('#update-payment-method').on('submit', async (e)=> {
        e.preventDefault()
		let error = '';
		let notification = {'message': 'An unknown error occurred, please contact support.', 'status': 'failed'};
		
		//some quick checks to make sure it's worth starting this
		if($('#cc').val() == ''){
			error += 'Credit card number is required <br/>'
		}
		if($('#exp').val() == ''){
			error += 'Credit card expiration is required <br/>'
		}
		if($('#cvc').val() == ''){
			error += 'CVC/Security is required <br/>'
		}
		if($('#billing_address').val() == ''){
			error += 'Billing Address is required <br/>'
		}
		if($('#billing_zip').val() == ''){
			error += 'Zip Code is required <br/>'
		}
		if($('#cardholder_name').val() == ''){
			error += 'Card holder name is required <br/>'
		}
		
		
		if(error == ''){
			//we keep a number of payment details in the database, so let's go get those
			let payment_details = await fetch('/onlinerequest/get-payment-details', {
				method: 'POST'
			})
		
			payment_details = await payment_details.json()
			console.log(payment_details);
			
			let exp = $('#exp').val();
			exp = exp.replace(/\D/g,'');
			
			//set-up the form for our API submission
			let add_new_card_data = new FormData()
			add_new_card_data.append('payment_profile_id', payment_details.ebiz_payment_profile_id);
			add_new_card_data.append('customer_token', payment_details.ebiz_customer_token);
			add_new_card_data.append('customer_internal_id', payment_details.ebiz_customer_internal_id);
			add_new_card_data.append('hubspot_company_id', payment_details.hubspot_company_id);
			add_new_card_data.append('client_id', payment_details.id)
			add_new_card_data.append('monthly_payment_day', payment_details.monthly_payment_day)
			add_new_card_data.append('enabled', payment_details.enabled)
			add_new_card_data.append('cc', $('#cc').val())
			add_new_card_data.append('exp', exp)
			add_new_card_data.append('cvc', $('#cvc').val())
			add_new_card_data.append('billing_address', $('#billing_address').val())
			add_new_card_data.append('billing_zip', $('#billing_zip').val())
			add_new_card_data.append('cardholder_name', $('#cardholder_name').val())
		
			console.log(add_new_card_data);
			//make the API call to our marketing api system
			let api_request = await fetch(window.api_source+"/api/update-payment-profile", {
				method: 'POST',
				body: add_new_card_data
			})
			
			let api_response = await api_request.json();
			console.log(api_response);
			
			if(api_response.error == true){
				error = api_response.error_message;
			}
		}
		console.log(error);
		if(error){
			location.hash = "#response-card-focus";
			$('#response-card').html(error);
			$('#response-card').addClass('error');
			$('#response-card').removeClass('success');
		}else{
			//location.hash = "#response-card-focus";
			//$('#response-card').html('Card Details Updated. Refresh this window to see results.');
			//$('#response-card').addClass('success');
			//$('#response-card').removeClass('error');
			notification.message = "Your credit card details were successfully updated.";
			notification.status = "success";
			sessionStorage.setItem("notification", JSON.stringify(notification));
			location.reload();
		}
    })
	
	
	$('#change-subscription').on('submit', async (e)=> {
        e.preventDefault();
		let error = '';
		let notification = {'message': 'An unknown error occurred, please contact support.', 'status': 'failed'};
		
		let confirmBuyNow = $('#confirmValue').val();
		if(confirmBuyNow == '_wait'){
			window.upgradeNowRan = false;
			confirmBox({
				'title'     : 'Upgrade Membership',
				'message'   : "You are about to upgrade your membership, which will be a recurring monthly charge (plus a one-time prorated charge) that will be charged on your next payment date. If you wish to proceed, please click CONTINUE",
				'submit'	: '#change-subscription',
				'items'		: 'true'
				
				});
				
			return
		}
		
		if(confirmBuyNow == 'true'){
			if(window.upgradeNowRan){
				console.log('Upgrade has already been run and was prevented from a duplicate entry.');
				return;
			}else{
				console.log('Processing Upgrade.');
				window.upgradeNowRan = true;
			}
			
			let confirmRemoveData = $('input[name="confirmItems"]:checked').val();
			
			console.log(confirmRemoveData);
			
			//some quick checks to make sure it's worth starting this
			if($('#subscription_id').val() == '' || $('#subscription_id').val() == 0){
				error += 'Subscription ID is required <br/>'
			}
			
			if(error == ''){

				//we keep a number of payment details in the database, so let's go get those
				let payment_details = await fetch('/onlinerequest/get-payment-details', {
					method: 'POST'
				})
				
				payment_details = await payment_details.json()
				console.log(payment_details);
				
				//set-up the form for our API submission
				let subscription_data = new FormData()
				subscription_data.append('client_id', payment_details.id);
				subscription_data.append('new_type_id', $('#subscription_id').val())
				subscription_data.append('nonprofit_multiplier', $('#nonprofit_multiplier').val())
				subscription_data.append('keep_addon', confirmRemoveData)
				subscription_data.append('additional_item_id', e.target.dataset.additionaladdon)
				
				console.log(subscription_data);
				//make the API call to our marketing api system
				let api_request = await fetch(window.api_source+"/api/upgrade-membership-payment-schedule", {
					method: 'POST',
					body: subscription_data
				})
				
				let api_response = await api_request.json();
				console.log(api_response);
				
				if(api_response.error == true){
					error = api_response.error_info.api_error;
				}
			}
			
			//good to go
			if(error != ''){
				location.hash = "#response-sub-focus";
				$('#response-sub').html(error);
				$('#response-sub').addClass('error');
				$('#response-card').removeClass('success');
			}else{
				//location.hash = "#response-sub-focus";
				//$('#response-sub').html('Membership Updated. Refresh this window to see results.');
				//$('#response-sub').addClass('success');
				//$('#response-card').removeClass('error');
				//let notification = {'message': 'Your subscription was successfully upgraded.', 'status': 'success'};
				notification.message = "Your subscription was successfully upgraded.";
				notification.status = "success";
				sessionStorage.setItem("notification", JSON.stringify(notification));
				location.reload();
			}
		}
    })
	
	$('.add-onetime-addon').on('submit', async (e)=> {
		e.preventDefault();
		let notification = {'message': 'An unknown error occurred, please contact support.', 'status': 'failed'};
		
		//var confirmBuyNow = confirm("You are about to add a new add-on, which will incur a one-time charge effective today. If you wish to proceed, please click OK");
		
		let confirmBuyNow = $('#confirmValue').val();
		let confirmID = $('#confirmBox').attr('data-id');
		if(confirmBuyNow == '_wait'){
			window.addOnetimeAddonRan = false;
			confirmBox({
				'title'     : 'Add a new Add-on',
				'message'   : "You are about to add a "+e.target.dataset.name+" add-on, which will be a one-time charge effective today, for a total amount of $"+e.target.dataset.totalamount+". If you wish to proceed, please click CONTINUE",
				'submit'	: '.add-onetime-addon',
				'id'		: e.target.dataset.addon
				});
				
			return
		}
		
		console.log(confirmID);
		console.log(e.target.dataset.addon);
		
		if(confirmBuyNow == 'true'){
			
			
			if(window.addOnetimeAddonRan){
				console.log('A one time add-on has already been run and was prevented from a duplicate entry.');
				return;
			}else{
				if(confirmID != e.target.dataset.addon){
					console.log('This one-time add-on was not requested for an upgrade.');
					return;
			}
				console.log('Processing the one-time add-on.');
				window.addOnetimeAddonRan = true;
			}
			
			/*
			if(confirmID != e.target.dataset.addon){
				console.log('This add-on was not requested for an upgrade.');
				return;
			}
			*/

			$('#main-loading-spinner').fadeIn()			
			
			//we keep a number of payment details in the database, so let's go get those
			let payment_details = await fetch('/onlinerequest/get-payment-details', {
				method: 'POST'
			})
			
			payment_details = await payment_details.json()
			//console.log(payment_details);
			
			//set-up the form for our API submission
			let addon_data = new FormData();
			addon_data.append('skt_client_id', payment_details.id);
			addon_data.append('new_type_id', e.target.dataset.addon);
			addon_data.append('next_payment_date', payment_details.next_payment_date);
			addon_data.append('recurring', e.target.dataset.recurring);
			
			let api_request = await fetch(window.api_source+"/api/add-new-onetime-addon", {
				method: 'POST',
				body: addon_data
			})
			
			let api_response = await api_request.json();
			console.log(api_response);
			
			$('#main-loading-spinner').fadeOut()
			
			if(api_response.error == true){
				notification.message = api_response.error_info.api_error;
				notification.status = 'failed';
				displayNotification(notification);
			}else{
				notification.message = "This addon has been added to your account. Please refresh this page to see the changes.";
				//notification.status = 'success';
				//notification.message = "The addon was successful added to your subscription.";
				notification.status = "success";
				sessionStorage.setItem("notification", JSON.stringify(notification));
				location.reload();
			}
				
		}
	});
	
	
	$('.add-additional-items-addon').on('submit', async (e)=> {
		e.preventDefault();
		let notification = {'message': 'An unknown error occurred, please contact support.', 'status': 'failed'};
		
		let confirmBuyNow = $('#confirmValue').val();
		let confirmID = $('#confirmBox').attr('data-id');
		if(confirmBuyNow == '_wait'){
			window.addAddlAddonRan = false;
			confirmBox({
				'title'     : 'Add a new Add-on',
				'message'   : "You are about to add a "+e.target.dataset.name+" add-on, which will be a recurring monthly charge of $"+e.target.dataset.baseamount+" (plus a one-time prorated charge of $"+e.target.dataset.upgradeamount+") for a total amount of $"+e.target.dataset.totalamount+", that will be charged on your next payment date. If you wish to proceed, please click CONTINUE",
				'submit'	: '.add-additional-items-addon',
				'id'		: e.target.dataset.addon
				});
				
			return
		}
		
		//var confirmBuyNow = confirm("You are about to add a "+e.target.dataset.name+" add-on, which will be a recurring monthly charge of $"+e.target.dataset.baseamount+" (plus a one-time prorated charge of $"+e.target.dataset.upgradeamount+") for a total amount of $"+e.target.dataset.totalamount+", that will be charged on your next payment date. If you wish to proceed, please click OK");
		
		if(confirmBuyNow == "true"){
			
			console.log("wha?");
			
			
			if(window.addAddlAddonRan){
				console.log('An Addl add-on has already been run and was prevented from a duplicate entry.');
				return;
			}else{
				if(confirmID != e.target.dataset.addon){
					console.log('This add-on was not requested for an upgrade.');
					return;
			}
				console.log('Processing Addl add-on.');
				window.addAddlAddonRan = true;
			}
			
			
			/*
			if(confirmID != e.target.dataset.addon){
				console.log('This add-on was not requested for an upgrade.');
				return;
			}
			confirmBuyNow = '_wait';
			*/
			
			$('#main-loading-spinner').fadeIn()
			
			//we keep a number of payment details in the database, so let's go get those
			let payment_details = await fetch('/onlinerequest/get-payment-details', {
				method: 'POST'
			})
			
			payment_details = await payment_details.json()
			//console.log(payment_details);
			
			//set-up the form for our API submission
			let addon_data = new FormData();
			addon_data.append('skt_client_id', payment_details.id);
			addon_data.append('new_type_id', e.target.dataset.addon);
			addon_data.append('next_payment_date', payment_details.next_payment_date);
			addon_data.append('recurring', e.target.dataset.recurring);
			addon_data.append('hasaddon', e.target.dataset.hasaddon);
			
			console.log(addon_data);
			
			let api_request = await fetch(window.api_source+"/api/add-or-upgrade-additional-items", {
				method: 'POST',
				body: addon_data
			})
			
			let api_response = await api_request.json();
			console.log(api_response);
			
			$('#main-loading-spinner').fadeOut()
			
			if(api_response.error == true){
				notification.message = api_response.error_info.api_error;
				notification.status = 'failed';
				//good to go
				displayNotification(notification);
			}else{
				notification.message = "This addon has been added to your account. Please refresh this page to see the changes.";
				//notification.status = 'success';
				//notification.message = "The addon was successful added to your subscription.";
				notification.status = "success";
				sessionStorage.setItem("notification", JSON.stringify(notification));
				location.reload();
			}
		}
	});
	
	
	$('#buy-now').on('submit', async (e)=> {
		e.preventDefault();
		let notification = {'message': 'An unknown error occurred, please contact support.', 'status': 'failed'};
		
		let confirmBuyNow = $('#confirmValue').val();
		if(confirmBuyNow == '_wait'){
			window.buyNowRan = false;
			confirmBox({
				'title'     : 'Unlock Your Full Account',
				'message'   : "You will be redirected back to the signup form at https://scan-im.com/ where you will be able to upgrade your current membership. Once you begin, you will have 30 minutes to complete your upgrade. Press 'Upgrade' to begin your upgrade now!",
				'submit'	: '#buy-now',
				'multi'		: 'false'
				
				});
				
			return;
		}
		
		/*var confirmRemoveData = confirm("Do you wish to keep your existing free trial data (items, rooms, locations), or start over with a clean slate? If you wish to keep your trial data, please click OK, otherwise click Cancel to have all existing data cleared.");*/
		
		if(confirmBuyNow == 'true'){
				
			if(window.buyNowRan){
				console.log('Buy now has already been run and was prevented from a duplicate entry.');
				return;
			}else{
				console.log('Processing buy now.');
				window.buyNowRan = true;
			}

			//set-up the form for our API submission.
			let buyNow_data = new FormData();
			buyNow_data.append('client_id', window.user.global_client_id);
			
			console.log('sending api request');
			let api_request = await fetch(window.api_source+"/api/process-upgrade-now", {
				method: 'POST',
				body: buyNow_data
			})
			
			let api_response = await api_request.json();
			console.log(api_response);
			
			if(api_response.error == true){
				notification.message = "An unexpected error occurred. Please, contact support to update your account.";
				notification.status = 'failed';
				displayNotification(notification);
				
			}else{
				notification.message = "You are being redirected to complete your upgrade.";
				notification.status = 'success'; 
				displayNotification(notification);
				
				window.open(window.api_source+"/signup?id="+api_response.id+"&key="+api_response.key);
				
				/*if (confirmKeepData == 'false'){
					console.log('Clearing Data');
					//	Removing the free trial data
					let clear_data = await fetch('/onlinerequest/clear-free-trial-data', {
						method: 'POST'
					});
					clear_data = await clear_data.json();
					
					console.log(clear_data);
					console.log('adsf');
				
					if(clear_data.error == true){
						notification.message = "Your full account has been activated. However the removal of the free trial data did not happen properly. Please contact support to finish the process.";
						notification.status = 'failed';
						
						//good to go
						sessionStorage.setItem("notification", JSON.stringify(notification));
						location.reload();
					}
					else{
						//purgeCacheAndIDB();
						notification.message = "Your full account has been activated and your previous data from the free trial has been cleared. Some areas of the site may feel limited until you log out and back in.";
						notification.status = 'success';
						sessionStorage.setItem("notification", JSON.stringify(notification));
						location.reload();
					}
				}else{
					notification.message = "Your full account has been activated and your previous data has been kept. Some areas of the site may feel limited until you log out and back in.";
					notification.status = 'success';
					sessionStorage.setItem("notification", JSON.stringify(notification));
					location.reload();
				}*/
			}
		}
		
	});
	
	$('#cancel-free-trial').on('submit', async (e)=> {
		e.preventDefault();
		let notification = {'message': 'An unknown error occurred, please contact support.', 'status': 'failed'};
		
		let confirmCancelFreeTrial = $('#confirmValue').val();
		if(confirmCancelFreeTrial == '_wait'){
			window.cancelFreeTrialRan = false;
			confirmBox({
				'title'     : 'End Free Trial',
				'message'   : "You are about to end your free trial and discontinue use of the ScanIM service. If you continue you will immediately be logged out and unable to access the app or your data again. If you wish to continue, please click CONTINUE.",
				'submit'	: '#cancel-free-trial',
				'multi'		: 'false'
				
				});
				
			return;
		}
		
		if(confirmCancelFreeTrial == 'true'){
			//
			if(window.cancelFreeTrialRan){
				console.log('Cancel free trial has already been run and was prevented from a duplicate entry.');
				return;
			}else{
				console.log('Processing cancel free trial.');
				window.cancelFreeTrialRan = true;
			}
			
			/*
			let api_request = await fetch("#", {
				method: 'POST',
				body: buyNow_data
			})
			
			let api_response = await api_request.json();
			console.log(api_response);
			
			if(api_response.error == true){
				notification.message = "An unexpected error occurred. Please, contact support to update your account.";
				notification.status = 'failed';
			}else{
				//
			}
			*/
			
		}
	});
	
}